
// Intro
// ---------------------------------------

%large-text {
    p {
        font-size: 1.4em;
        @include small {
            font-size: 1.2em;
        }
    }
}
span.icon {
    @extend %svg;
    display: inline-block;
    vertical-align: middle;
    &.ux {
        width: (53/16)+em;
        height: (36/16)+em;
    }
    &.design {
        width: (39/16)+em;
        height: (35/16)+em;
    }
    &.marketing {
        width: (37/16)+em;
        height: (42/16)+em;
    }
}
.button-click {
    @extend %button-style;
    text-decoration: none;
    span:after {
        background: $primary;
    }
}
.cat-link {
    text-decoration: none;
    color: $b;
    margin-right: 1em;
    .button-click, .icon-holder {
        @extend %vam;
    }
    .icon-holder {
        width: (53/16)+em;
        margin-right: 0.5em;
        text-align: center;
        @include small {
            font-size: 0.9em;
        }
    }
    span.icon {
        display: inline-block;
    }
    @include st {
        text-decoration: none;
        .button-click {
            span:after {
                bottom: 5px;
            }
        }
    }
}
.intro {
    @extend %section-pad;
    position: relative;
    z-index: 2;
    .box {
        background: $w;
        padding: 2.5rem 2.5rem 3rem 2.5rem;
        box-shadow: 0.8em 0.8em 0 rgba(0,0,0,0.1);
        @include media(0, 1000) {
            padding-left: 1.75rem;
            padding-right: 1.75rem;
            box-shadow: 0.65em 0.65em 0 rgba(0,0,0,0.1);
        }
        @include small {
            padding: 1.75rem 1.55rem 2.5rem 1.55rem;
        }
    }
    &.case-study {
        margin-top: 19rem;
        @include media(0, 1000) {
            margin-top: 17.5rem;
        }
        @include media(0, $tablet-break) {
            padding-bottom: 3rem;
        }
        @include small {
            margin-top: 6.25rem;
        }
        .columns.content {
            @include media(0, 1000) {
                @include grid-column(8);
            }
            @include small {
                @include grid-column(12);
                margin-bottom: 1.5rem;
            }
        }
    }
    h1, h2 {
        @extend h3;
    }
    .row {
        @include clear;
        &.links {
            margin-top: 1rem;
        }
    }
    .columns.content {
        @include grid-column(8);
        @include media(0, 1000) {
            @include grid-column(10);
        }
        @include small {
            @include grid-column(12);
        }
    }
    &.one-col {
        @extend %large-text;
        .columns.content {
            padding-right: 2em;
            @include small {
                padding-right: rem-calc($gutter*0.375);
            }
        }
    }
    &.two-col {
        .columns.content {
            column-count: 2;
            column-gap: $gutter;
            @include media(0, 1000) {
                column-count: 1;
                column-gap: 0;
            }
        }
        .columns.links {
            .inner {
                padding-left: 1rem;
                @include media(0, 1000) {
                    padding-left: 0;
                }
            }
            @include grid-column(4);
            @include small {
                @include grid-column(12);
            }
            h5 {
                margin-bottom: 0;
                margin-top: 1rem;
                font-size: 1.35rem;
                &:first-child {
                    margin-top: 0;
                }
                @include small {
                    font-size: 1.2rem;
                }
            }
        }
    }
    @include small {
        .cat-link {
            display: block;
            margin-bottom: 1rem;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
.intro+.intro {
    padding-top: 0;
}




