
// Social
// ---------------------------------------

// Options
$social-height: 1.5em;
$social-width: 1.5em;
$social-pad: 0.25em;
$on_white: true;
$social-text-color: false;
$social-bg-color: true;
$social-hover-color: $secondary;
$social-float: true;

ul.social {
	font-size: 1.4em;
	@extend %no-list;
	@if $social-float == true {
		@include clear;
		li {
			float:left;
		}
	}
	li {
		@if $social-float == false {
			display: inline-block;
		}
		span.text {
			@extend .vh;
		}
		a {
			display: block;
			@include transition;
			padding: $social-pad;
			@if $social-bg-color {
				color: $w;
			}
			&:before, span.dots {
				width: $social-width;
				line-height: $social-height;
				text-align: center;
			}
		}
		&.facebook {
			a {
				@if $social-text-color {
					color: get-social-colour(facebook);
					@include st {
						color: $social-hover-color;
					}
				}
				@if $social-bg-color {
					background: get-social-colour(facebook);
					@include st {
						background: $social-hover-color;
					}
				}
				@include fa(facebook);
			}
		}
		&.twitter {
			a {
				@if $social-text-color {
					color: get-social-colour(twitter);
					@include st {
						color: $social-hover-color;
					}
				}
				@if $social-bg-color {
					background: get-social-colour(twitter);
					@include st {
						background: $social-hover-color;
					}
				}
				@include fa(twitter);
			}
		}
		&.linkedin {
			a {
				@if $social-text-color {
					color: get-social-colour(linkedin);
					@include st {
						color: $social-hover-color;
					}
				}
				@if $social-bg-color {
					background: get-social-colour(linkedin);
					@include st {
						background: $social-hover-color;
					}
				}
				@include fa(linkedin);
			}
		}
		&.youtube {
			a {
				@if $social-text-color {
					color: get-social-colour(youtube);
					@include st {
						color: $social-hover-color;
					}
				}
				@if $social-bg-color {
					background: get-social-colour(youtube);
					@include st {
						background: $social-hover-color;
					}
				}
				@include fa(youtube);
			}
		}
		&.google-plus {
			a {
				@if $social-text-color {
					color: get-social-colour(google-plus);
					@include st {
						color: $social-hover-color;
					}
				}
				@if $social-bg-color {
					background: get-social-colour(google-plus);
					@include st {
						background: $social-hover-color;
					}
				}
				@include fa(google-plus);
			}
		}
		&.pinterest {
			a {
				@if $social-text-color {
					color: get-social-colour(pinterest);
					@include st {
						color: $social-hover-color;
					}
				}
				@if $social-bg-color {
					background: get-social-colour(pinterest);
					@include st {
						background: $social-hover-color;
					}
				}
				@include fa(pinterest);
			}
		}
		&.flickr {
			a {
				background: $w;
				@if $on_white {
					background: #f1f1f1;
				}
				@if $social-text-color or $social-bg-color {
					@include st {
						background: $social-hover-color;
						span.dots {
							&:before, &:after {
								background: $w;
							}
						}
					}
				}
			}
			span.dots {
				display: inline-block;
				&:before, &:after {
					content:"";
					width: 0.45em;
					border-radius: 100%;
					height: 0.45em;
					display: inline-block;
					background: $b;
					margin-right: 0.1em;
					margin-bottom: 0.15em;
					@include transition;
				}
				&:after {
					margin-right: 0;
				}
				@if $social-text-color or $social-bg-color {
					&:before {
						background: get-social-colour(flickr-blue);
					}
					&:after {
						background: get-social-colour(flickr-pink);
					}
				}
			}
		}
		&.rss {
			a {
				@if $social-text-color {
					color: get-social-colour(rss);
					@include st {
						color: $social-hover-color;
					}
				}
				@if $social-bg-color {
					background: get-social-colour(rss);
					@include st {
						background: $social-hover-color;
					}
				}
				@include fa(rss);
			}
		}
	}
}




