
// Services
// ---------------------------------------

.services+.work {
    margin-top: 1.25rem;
    @include small {
        margin-top: 0.5rem;
    }
}
.services {
    .button-click {
        font-size: 1.5rem;
        span {
            &:after {
                bottom: 1px;
            }
            @include st {
                &:after {
                    bottom: 6px;
                }
            }
        }
    }
    @extend %section-pad;
    margin-bottom: -1.25rem;
    @include small {
        margin-bottom: -0.5rem;
    }
    &:last-child {
        margin-bottom: 0;
    }
    .copy-container {
        max-width: 720px;
    }
    .copy-container, ul {
        padding-left: 4em;
        @include small {
            padding-left: 3.65em;
            // font-size: 0.95em;
        }
    }
    p {
        margin-top: 0.5em;
        // font-size: 1.15em;
        margin-bottom: 1.5em;
    }
    ul {
        padding-top: 0;
        @extend .copy-container;
        @extend %no-list;
        column-count: 3;
        column-gap: rem-calc($gutter);
        // margin-bottom: -1.5em;
        @include media(0, 1000) {
            column-count: 2;
        }
        @include small {
            column-count: 1;
        }
        li {
            @extend p;
            margin-bottom: 1.5em;
            font-size: 1.1em;
            @include small {
                margin-bottom: 1em;
            }
            margin-top: 0;
            line-height: 1.5;
            &:first-child {
                margin-top: 0;
            }
            a {
                color: $b;
                font-weight: bold;
                @include st {
                    color: $primary;
                }
            }
        }
    }
}
.content+.services {
    padding-top: 1rem;
    @include small {
        padding-top: 1rem;
    }
}
.services+.services {
    padding-top: 0;
}




