
// Global
// ---------------------------------------
$use_foundation: true;
$use_bootstrap: false;
// @if $use_foundation {
// 	@debug "Framework is using Foundation";
// }
// @if $use_bootstrap {
// 	@debug "Framework is using Bootstrap";
// }

// Grid variables
// ---------------------------------------
$gutter:30px;
$cols: 12;
$grid-max: 8;

// Use flexbox? (Foundation only)
// ---------------------------------------
$flexbox-settings: false;

// Breakpoints
// ---------------------------------------
$small: 480px;
$medium: 768px;
$large: 1140px;
$xlarge: 1440px;
$xxlarge: $xlarge;

$tablet-break: 1000px;

// Colours
// ---------------------------------------
$b: #111216;
$w: white;
$red: #EF767A;
$yellow: #FFE66D;
$green: #5BE7AA;
$blue: #7EBCE6;

$primary: $green;
$secondary: $blue;
$success: $green;
$warning: $yellow;
$alert: $red;
$light-gray: #ccc;
$medium-gray: #979797;
$dark-gray: $b;
$social-colours: (
	facebook: #3b5998,
	twitter: #00aced,
	google-plus: #dd4b39,
	linkedin: #007bb6,
	youtube: #bb0000,
	instagram: #517fa4,
	pinterest: #cb2027,
	flickr-blue: #0062d6,
	flickr-pink: #ff0084,
	tumblr: #32506d,
	foursquare: #0072b1,
	dribbble: #ea4c89,
	vine: #00bf8f,
	rss: #ff6f00
);
$file-colours: (
	pdf: #BD0100,
	doc: #29569A,
	ppt: #D64401
);

// Typography
// ---------------------------------------
$body-font: 'Heebo', Helvetica, Arial, sans-serif;
$heading-font: 'Work Sans', Helvetica, Arial, sans-serif;
$mono-font: Courier, monospace;

// Defaults
// ---------------------------------------

// Global
$font-size: 16px;
$d-time: 0s;
$d-rad: 0;
$d-pad: 1rem;
$d-margin: 1rem;
$d-line-height: 1.6;
$d-ease: ease-in-out;

// Headings
$d-heading1: 50;
$d-heading2: 42;
$d-heading3: 30;
$d-heading4: 24;
$d-heading5: 20;
$d-heading6: 16;
$d-heading1-medium: $d-heading1;
$d-heading2-medium: $d-heading2;
$d-heading3-medium: $d-heading3;
$d-heading4-medium: $d-heading4;
$d-heading5-medium: $d-heading5;
$d-heading6-medium: $d-heading6;
$d-heading1-small: 38;
$d-heading2-small: 32;
$d-heading3-small: 26;
$d-heading4-small: 22;
$d-heading5-small: 18;
$d-heading6-small: 16;
$d-heading-line-height: 1.2;

// Links
$d-hover: $secondary;
$d-hover-underline: underline;




