
// Footer
// ---------------------------------------

%alt-heading {
    font-size: 1.2rem;
    font-family: $heading-font;
    font-weight: 300;
    text-transform: lowercase;
    @include small {
        font-size: 1.1rem;
    }
}
#sitemap {
    background: $w;
    z-index: 2;
    position: relative;
    padding-top: 3.5rem;
    margin-top: 3.5rem;
    padding-bottom: 4.5rem;
    font-size: 1.1em;
    @include small {
        margin-top: 2rem;
        font-size: 1em;
        padding-top: 2.5rem;
        padding-bottom: 3.5rem;
    }
    h4 {
        margin: 0;
    }
    h5 {
        @extend %alt-heading;
        margin-bottom: 1.5rem;
    }
    ul {
        @extend %no-list;
        li {
            margin-bottom: 1rem;
            &:last-child {
                margin-bottom: 0;
            }
            @include small {
                margin-bottom: 0.75rem;
            }
        }
    }
    a {
        color: $b;
        @include st {
            text-decoration: underline;
        }
    }
    h4 a {
        @include st {
            text-decoration: none;
        }
    }
    .row {
        @include clear;
        > .columns {
            @include grid-column(9);
            @include grid-column-end;
            padding-right: 0 !important;
            &.section-link {
                @include grid-column(3);
            }
            @include media(0, $tablet-break) {
                @include grid-column(12);
                &.section-link {
                    @include grid-column(12);
                    margin-bottom: 2.5rem;
                }
                &.items {
                    padding-left: 0 !important;
                }
            }
            @include small {
                &.section-link {
                    padding-left: rem-calc($gutter*0.5);
                    padding-right: rem-calc($gutter*0.5);
                }
            }
            .columns {
                width: 25%;
                float: left;
                @include small {
                    width: 50%;
                    margin-bottom: 2.5rem;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    &:nth-last-child(2) {
                        margin-bottom: 0;
                    }
                }
                @include media(0, 480) {
                    width: 100%;
                    &:nth-last-child(2) {
                        margin-bottom: 2.5rem;
                    }
                }
            }
        }
    }
}
footer {
    background: $b;
    @extend .black;
    @include clear;
    position: relative;
    z-index: 2;
    padding: 3.75em 0 4em 0;
    @include small {
        padding: 3em 0 3.5em 0;
    }
    p {
        font-size: 1.1em;
        @include small {
            font-size: 1em;
        }
    }
    h6 {
        @extend %alt-heading;
        margin-bottom: 1.5rem;
    }
    ul {
        @extend %no-list;
        li {
            margin-bottom: 1rem;
            @extend p;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    a {
        color: $w;
        @include st {
            color: $light-gray;
        }
    }
    .signup-form {
        text-align: right;
        margin-top: -0.3em;
        padding-right: 1.5em;
        @include clear;
        input {
            color: $w;
            &[type=email], &[type=text] {
                &:focus, &:active {
                    border-bottom: 1px solid $light-gray;
                }
            }
        }
        input[type=submit] {
            @include st {
                color: $w;
            }
        }
        .submit-button {
            display: inline-block;
        }
        @include small {
            padding-right: 0;
        }
    }
    .items {
        @include clear;
        .columns {
            @include grid-column(3);
            @include small {
                @include grid-column(6);
                margin-bottom: 2.5rem;
                &:last-child {
                    margin-bottom: 0;
                }
                &:nth-last-child(2) {
                    margin-bottom: 0;
                }
            }
            @include media(0, 480) {
                @include grid-column(12);
                &:nth-last-child(2) {
                    margin-bottom: 2.5rem;
                }
            }
        }
    }
    address {
        @extend p;
        font-style: normal;
    }
    .copyright {
        margin-top: 3rem;
        color: $light-gray;
        @include small {
            margin-top: 2rem;
        }
    }
}




