
// Blog
// ---------------------------------------

.blog-listing {
    @include clear;
    padding-top: 0;
    @extend %section-pad;
    .columns {
        @include grid-column(3);
        @include grid-column-end;
        @include media(0, $tablet-break) {
            @include grid-column(4);
            @include grid-column-end;
            &.empty {
                display: none;
            }
        }
        @include small {
            @include grid-column(12);
            margin-bottom: 2.5rem;
            &:last-child {
                margin-bottom: 0;
            }
        }
        p {
            margin: 0;
        }
        .link h3 {
            margin: 0;
        }
        h4, h3 {
            margin-top: 0.25rem;
        }
        .post-holder {
            border-top: 1px solid $light-gray;
            padding-top: 1.25em;
        }
    }
    &.main {
        @extend %section-pad;
        margin-bottom: -3.5rem;
        @include media(0, 1000) {
            margin-bottom: -2.75rem;
        }
        @include media(0, 600) {
            margin-bottom: -1.5rem;
        }
        .columns {
            @include grid-column(4);
            @include grid-column-end;
            margin-bottom: 3rem;
            @include media(0, 1000) {
                @include grid-column(6);
                @include grid-column-end;
                margin-bottom: 2.75rem;
            }
            @include media(0, 600) {
                @include grid-column(12);
                @include grid-column-end;
                margin-bottom: 1.5rem;
            }
        }
    }
}
.work+.blog-listing {
    @include small {
        padding-top: 3.25rem;
    }
}




