
/*  exolab
    ================================
    Habanero
    http://habanero.digital/
*/

@import "utility/variables";

/* Foundation
   ================================ */
@import "foundation-settings";
@import "foundation";
@include foundation-everything;

/* Bootstrap
   ================================ */
// @import "bootstrap-settings";
// @import "bootstrap";
// html {
// 	font-size: $font-size-base;
// }

/* Plugins
   ================================ */
// @import "font-awesome";
// @import "slick";
// @import "video-js";

/* Font Awesome 5
   ================================ */
@import "font-awesome/fa-regular";
@import "font-awesome/fa-solid";
@import "font-awesome/fa-brands";
@import "font-awesome/icons";

/* Base
   ================================ */
@import "utility/mixins";
@import "utility/util";
@import "global/grid";
@import "global/typography";
@import "global/buttons";
@import "global/forms";
@import "global/header";
@import "global/footer";

/* Components
   ================================ */
@import "components/social";
@import "components/hero";
@import "components/intro";
@import "components/case-studies";

/* Partials
   ================================ */
@import "partials/blog";
@import "partials/services";

.svg-sprite {
	display: none;
}
.vh {
    overflow: hidden;
    position: absolute;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}
.select-full, .select-full-blank {
    cursor: pointer;
}




