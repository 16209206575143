
// Typography
// ---------------------------------------

h1 {
	line-height: 1.05;
}
h2 {
	line-height: 1.15;
}
@for $i from 1 through 6 {
	h#{$i} {
		font-weight: 700;
		margin-bottom: 1.25rem;
		margin-top: 2rem;
		&:first-child {
			margin-top: 0;
		}
		&:last-child {
			margin-bottom: 0;
		}
		a {
			color: $b;
			@include st {
				text-decoration: none;
			}
			span {
				position: relative;
				z-index: 1;
				&:after {
					content: "";
					display: inline-block;
					width: 99%;
					height: 5px;
					bottom: 0;
					left: 0;
					position: absolute;
					z-index: -1;
					background: $primary;
				}
			}
		}
	}
	.white {
		h#{$i} {
			a {
				color: $w;
			}
		}
	}
}
.copy-container {
	max-width: 780px;
}
h3 {
	a {
		span:after {
			bottom: 2px;
		}
		@include st {
			span:after {
				bottom: 7px;
			}
		}
	}
}
h4 {
	a {
		span:after {
			bottom: 1px;
		}
		@include st {
			span:after {
				bottom: 6px;
			}
		}
	}
}
h5 {
	a {
		span:after {
			bottom: 0px;
		}
		@include st {
			span:after {
				bottom: 5px;
			}
		}
	}
}
section.content {
	padding: 3rem 0 2.5rem 0;
	.row {
		@include clear;
	}
	.columns {
		@include grid-column(8);
		&.form {
			@include grid-column(3);
			@include media(0, 1300) {
				@include grid-column(4);
			}
		}
		@include small {
			@include grid-column(12);
			&.form {
				@include grid-column(12);
				margin-top: 2rem;
			}
		}
	}
	@include small {
		padding: 1.75rem 0 1.25rem 0;
	}
}
section.content+section.intro {
	padding-top: 1.5rem;
}
section.content+.content-rows {
	padding-top: 2rem;
}
.blog-post {
	.copy-container {
		.meta {
			margin: 0 0 1.25em 0;
			span.cat {
				text-transform: uppercase;
				font-weight: bold;
			}
			span.sep {
				color: $medium-gray;
				font-size: 1.1em;
			    margin-left: .35rem;
	    		margin-right: .35rem;
			}
		}
		> div {
			margin: 1.75em 0;
			small {
				margin-top: 0.5em;
				font-size: 1em;
				display: block;
			}
		}
	}
}
.content {
	h2 {
		@extend h3;
	}
	ul {
	    li {
	        @extend %list-style;
	    }
	}
	address {
		@extend p;
		font-style: normal;
	}
	ol, ul {
		padding-left: 1rem;
		margin-top: 1.5em;
		margin-bottom: 1.75em;
		ul, ol {
			margin-top: 0.8em;
		}
		li {
			font-size: 1.2em;
			li {
				font-size: 1em;
			}
			@include small {
				font-size: 1.1em;
			}
			margin-bottom: 0.8em;
		}
		&:first-child {
			margin-top: 0;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
	blockquote {
		border-left: 1px solid $light-gray;
		padding-left: 2rem;
		margin: 1.25em 0 1.5em 0;
		&:last-child {
			margin-bottom: 0;
		}
		&:first-child {
			margin-top: 0;
		}
		p {
			font-style: italic;
		}
		h4, h5 {
			@extend %alt-heading;
		}
	}
	p a, li a {
		color: $b;
		text-decoration: underline;
		@include st {
			color: $primary;
		}
	}
}
p {
	font-size: 1.2em;
	margin-bottom: 1.5em;
	@include small {
		font-size: 1.1em;
	}
	&:last-child {
		margin-bottom: 0;
	}
}