
// Buttons & Links
// ---------------------------------------

a, .btn-link {
	@extend %link-style;
	@include transition;
	&:hover {
		@extend %link-style_hover;
	}
}
.btn, .button {
	@include transition;
	@include st() {
		@extend %button-style_hover;
	}
	&.primary, &.btn-primary {
		@extend %button-style;
		background: none;
		span:after {
			background: $primary;
		}
		@include st() {
			background: none;
		}
	}
	&.secondary, &.btn-secondary, &.btn-info {
		@extend %button-style-small;
		background: none;
		span:after {
			background: $primary;
		}
		@include st() {
			background: none;
		}
	}
	&.success, &.btn-success {
		@extend %button-style;
		background: none;
		span:after {
			background: $success;
		}
		@include st() {
			background: none;
		}
	}
	&.warning, &.btn-warning {
		@extend %button-style;
		background: none;
		span:after {
			background: $warning;
		}
		@include st() {
			background: none;
		}
	}
	&.alert, &.btn-danger {
		@extend %button-style;
		background: none;
		span:after {
			background: $alert;
		}
		@include st() {
			background: none;
		}
	}
}
button, input[type=submit] {
	@extend .button;
	@extend .button.secondary;
	@extend .btn-secondary;
	@include st() {
		@extend %button-style_hover;
		background-color:$dark-gray;
	}
}