
// Case Studies
// ---------------------------------------

%txy {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
@mixin simplecols($cols, $margin) {
    width: (100 - ($margin * ($cols - 1)))/$cols;
    margin-right: $margin;
    float: left;
    @for $i from 1 through ($cols - 1) {
        &:nth-child(#{$i}n) {
            margin-right: $margin;
        }
    }
    &:nth-child(#{$cols}n) {
        margin-right: 0;
    }
}
.logos {
    padding-bottom: 2.25rem;
    &.home {
        padding-bottom: 0;
    }
    @include small {
        padding-bottom: 1.25rem;
    }
    .title {
        p {
            margin-bottom: 1rem;
        }
    }
    .items {
        @include clear;
        margin-bottom: -1.25rem;
        @include media(0, $tablet-break) {
            margin-bottom: -1rem;
        }
        @include media(0, 480) {
            margin-bottom: -0.65em;
        }
        ul {
            @extend %no-list;
            li {
                @include simplecols(4, 1.75%);
                background: $w;
                text-align: center;
                height: 220px;
                margin-bottom: 1.25rem;
                position: relative;
                img {
                    max-height: 110px;
                    max-width: 155px;
                    @extend %txy;
                }
                @include media(0, $tablet-break) {
                    @include simplecols(3, 3.33%);
                    height: 200px;
                    margin-bottom: 1rem;
                    img {
                        max-height: 95px;
                        max-width: 150px;
                    }
                }
                @include small {
                    @include simplecols(2, 3%);
                    height: 165px;
                    margin-bottom: 1em;
                    img {
                        max-height: 85px;
                        max-width: 130px;
                    }
                }
                @include media(0, 480) {
                    height: 120px;
                    margin-bottom: 0.65em;
                    img {
                        max-height: 65px;
                        max-width: 100px;
                    }
                }
            }
        }
    }
    @include media(0, $tablet-break) {
        &.home {
            .items {
                ul {
                    li:last-child {
                        display: none;
                    }
                }
            }
        }
    }
    @include small {
        &.home {
            .items {
                ul {
                    li:last-child {
                        display: block;
                    }
                }
            }
        }
    }
}
.screen {
    display: block;
    position: relative;
    @include aspect-ratio(1085, 625);
    background-image: url('../img/screen.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    max-width: 1085px;
    .holder {
        position: absolute;
        width: 76%;
        height: 82.5%;
        left: 12%;
        top: 6.225%;
        overflow: hidden;
        img {
            width: 100%;
        }
    }
    .yellow & {
        .holder {
            background: $yellow;
        }
    }
    .green & {
        .holder {
            background: $green;
        }
    }
    .red & {
        .holder {
            background: $red;
        }
    }
    .blue & {
        .holder {
            background: $blue;
        }
    }
}
.screen-mobile {
    display: block;
    position: relative;
    @include aspect-ratio(380, 770);
    background-image: url('../img/screen-mobile.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    max-width: 700px;
    .holder {
        position: absolute;
        width: 86.5%;
        height: 76%;
        left: 7%;
        top: 12.225%;
        overflow: hidden;
        img {
            width: 100%;
        }
    }
    .yellow & {
        .holder {
            background: $yellow;
        }
    }
    .green & {
        .holder {
            background: $green;
        }
    }
    .red & {
        .holder {
            background: $red;
        }
    }
    .blue & {
        .holder {
            background: $blue;
        }
    }
}
.work {
    @extend %section-pad;
    &.more {
        padding-top: 0;
    }
    .row {
        @include clear;
        margin-bottom: -3.75rem;
        @include small {
            margin-bottom: -3rem;
        }
        &.title {
            h3 {
                margin-bottom: 1rem;
            }
            .columns {
                @include grid-column(12);
                margin-bottom: 0;
            }
        }
        .columns {
            @include grid-column(6);
            @include grid-column-end;
            margin-bottom: 3.75rem;
            p {
                margin: 0;
            }
            h4 {
                margin: 0;
                max-width: 490px;
            }
            @include small {
                @include grid-column(12);
                margin-bottom: 3rem;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .image {
        overflow: hidden;
        @include aspect-ratio(58, 36);
        margin-bottom: 1.25rem;
        .screen {
            position: absolute;
            width: 135%;
            right: -32.5%;
            bottom: -42.5%;
        }
        .screen-mobile {
            position: absolute;
            width: 60%;
            left: 50%;
            margin-left: -30%;
            top: 15%;
        }
    }
    .yellow {
        .image {
            background: $yellow;
        }
    }
    .green {
        .image {
            background: $green;
        }
    }
    .red {
        .image {
            background: $red;
        }
    }
    .blue {
        .image {
            background: $blue;
        }
    }
}
.work+.logos {
    @include small {
        padding-top: 3.5rem;
    }
}
.content+.work {
    padding-top: 1.5rem;
    @include small {
        padding-top: 1rem;
    }
}
.content-rows {
    padding-bottom: 3.5rem;
    @include small {
        padding-bottom: 1rem;
    }
    section {
        padding-bottom: 5rem;
        @include small {
            padding-bottom: 3rem;
        }
        &:last-child {
            padding-bottom: 0;
        }
        .row {
            @include clear;
            &.title {
                h3 {
                    margin-bottom: 1.5rem;
                }
                .columns {
                    @include grid-column(12);
                }
            }
        }
    }
}
.content-rows section.branding {
    .row.title {
        h3 {
            margin-bottom: 0.75rem;
        }
    }
}
.content-rows+.intro {
    padding-top: 2rem;
}
.branding {
    position: relative;
    .row.title {
        h3 {
            margin-bottom: 0.75rem;
        }
    }
    .copy-container {
        p {
            margin-bottom: 1.75em;
        }
    }
    .columns {
        @include grid-column(6);
        @include media(0, 950) {
            @include grid-column(12);
        }
        &.content {
            padding-right: 2rem;
        }
        &.logo {
            top: -3rem;
            position: absolute;
            right: rem-calc($gutter*0.35);
            height: calc(100% + 3rem);
            padding-left: 4rem;
            .toggles {
                @include clear;
                @include small {
                    &.none {
                        display: none;
                    }
                }
            }
            @include media(0, 1200) {
                padding-left: 0;
            }
            @include media(0, 950) {
                position: static;
                right: auto;
                height: auto;
                margin-top: 1rem;
                padding-left: $gutter*0.5;
            }
            a {
                @extend %alt-heading;
                color: $b;
                text-decoration: underline;
                @include st {
                    color: $primary;
                }
                &#after_toggle {
                    float: right;
                }
                &.active {
                    opacity: 0.5;
                    text-decoration: none;
                    @include st {
                        opacity: 1;
                    }
                }
            }
        }
    }
    .colour-row {
        @include clear;
        margin-top: 1rem;
        @include media(0, 950) {
            width: 50%;
            padding-right: $gutter;
            float: left;
        }
        @include small {
            width: 100%;
            padding-right: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
        h4 {
            @extend %alt-heading;
        }
        span.colour {
            width: 50px;
            height: 50px;
            margin: 0 0.35em 0.35em 0;
            float: left;
            &.brand {
                cursor: pointer;
            }
            @include media(0, 480) {
                width: 45px;
                height: 45px;
            }
        }
    }
    .logo-after, .logo-before {
        position: relative;
        display: none;
        height: 86.5%;
        margin-top: 1rem;
        @include media(0, 950) {
            height: auto;
            @include aspect-ratio(54, 36);
        }
        img {
            max-height: 150px;
            max-width: 310px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            @include media(0, 500) {
                max-height: 95px;
                max-width: 200px;
            }
        }
        &.active {
            display: block;
        }
    }
}
.process {
    &.process-continue {
        margin-top: -4rem;
        @include small {
            margin-top: -2rem;
        }
    }
    @include clear;
    @include small {
        .row.screens {
            padding-right: 0;
            .columns {
                padding-right: 0;
            }
        }
    }
    .line {
        position: absolute;
        left: 3.5%;
        top: 0;
        overflow: hidden;
        width: 93%;
        height: 100%;
        z-index: 0;
        @include small {
            width: 85%;
            left: 7.5%;
        }
        &:after {
            content:"";
            display: block;
            border-bottom: 1px solid $medium-gray;
            width: 100%;
            height: 0;
            top: 50%;
            position: absolute;
            left: 50%;
            transform: translate(-50%, -50%) rotate(25deg);
            @include small {
                transform: translate(-50%, -50%) rotate(0deg);
                margin-top: -60px;
            }
        }
    }
    .holder {
        @include clear;
        position: relative;
        height: 70vh;
        min-height: 620px;
        max-height: 750px;
        @include media(0, 1100) {
            min-height: 450px;
            max-height: 620px;
        }
        @include small {
            height: auto;
            min-height: 0;
            max-height: none;
            overflow: scroll;
        }
    }
    .next-label {
        position: absolute;
        left: 100%;
        top: -0.35rem;
        margin-left: 13%;
        z-index: 1;
        @extend p;
        font-size: 1.1em;
        display: block;
        width: 50%;
        text-align: center;
        &:before {
            content:"";
            border-bottom: 1px solid $light-gray;
            position: absolute;
            width: 120px;
            right: 100%;
            z-index: 0;
            top: 50%;
        }
        &:after {
            content:"";
            border-left: 1px solid $light-gray;
            width: 1px;
            height: 120px;
            top: 50%;
            left: 50%;
            z-index: 0;
            position: absolute;
        }
        span {
            background: #f6f6f6;
            position: relative;
            z-index: 2;
            padding: 0.5em;
        }
        @include small {
            display: none;
        }
    }
    .mob-screen {
        $margin-w: 3%;
        border: 1px solid $light-gray;
        @include aspect-ratio(32, 50);
        max-width: 320px;
        background: $w;
        @include simplecols(4, $margin-w);
        position: absolute;
        $col-w: 22.75%;
        @include media(0, 1100) {
            @include simplecols(3, $margin-w);
        }
        @include small {
            @include simplecols(4, $margin-w*2);
        }
        .caption {
            @extend p;
            position: absolute;
            margin: 1rem 0;
            white-space: normal;
            &:last-child {
                margin: 1rem 0;
                @include small {
                    margin-bottom: 0;
                }
            }
            @include media(0, 1100) {
                font-size: 1.1em;
            }
        }
        .screen-holder {
            overflow: hidden;
            position: absolute;
            background: #f6f6f6;
            top: 0;
            z-index: 3;
            left: 0;
            width: 100%;
            height: 100%;
            img {
                width: 100%;
                display: block;
            }
        }
        &:first-child {
            z-index: 1;
            left: 0;
            top: 0;
            .caption {
                top: 100%;
            }
        }
        &:nth-child(2) {
            z-index: 2;
            left: $col-w + $margin-w;
            top: 44%;
            // top: 50%;
            transform: translateY(-50%); // subtract
            .caption {
                top: 100%;
            }
            @include media(0, 1100) {
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
        &:nth-child(3) {
            z-index: 3;
            right: $col-w;
            top: 56%;
            // top: 50%;
            transform: translateY(-50%); // add
            .caption {
                bottom: 100%;
            }
            @include media(0, 1100) {
                transform: none;
                right: 0;
                bottom: 0;
                top: auto;
                .next-label {
                    display: none;
                }
            }
        }
        &:last-child {
            z-index: 4;
            right: 0;
            bottom: 0;
            .caption {
                bottom: 100%;
            }
            @include media(0, 1100) {
                display: none;
            }
        }
        @include small {
            position: static;
            &:first-child, &:nth-child(2), &:nth-child(3), &:last-child {
                position: relative;
                left: auto;
                top: auto;
                transform: none;
                right: auto;
                bottom: auto;
                display: block;
                width: 230px;
                float: none;
                margin-bottom: 120px;
                display: inline-block;
                .caption {
                    bottom: auto;
                    top: 100%;
                }
            }
            &:last-child {
                margin-right: rem-calc(($gutter*0.2 + $gutter/2));
            }
        }
    }
    .mob-screen:nth-child(2).has-label+.mob-screen .caption, .mob-screen:nth-child(3).has-label+.mob-screen .caption {
        display: none;
    }
    @include small {
        .scroll-container {
            white-space: nowrap;
        }
    }
}
.large-screen {
    text-align: center;
    .screen {
        display: inline-block;
    }
}
.mobile-screens {
    text-align: center;
    .screen-mobile {
        display: inline-block;
        vertical-align: top;
        width: 25%;
        margin-right: 6.5%;
        &:last-child {
            margin-right: 0;
        }
        @include media(0, $tablet-break) {
            width: 33%;
            margin-right: 5%;
        }
        @include small {
            width: 45%;
            margin-right: 5%;
        }
        @include media(0, 480) {
            &:last-child {
                display: none;
            }
            width: 67.5%;
            margin-right: 0;
        }
    }
}
.annotation {
    .screen-holder {
        @include aspect-ratio(16, 10.5);
        max-width: 880px;
        border: 1px solid $light-gray;
        .image-container {
            overflow: hidden;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    .columns {
        @include grid-column(4);
        &.image {
            @include grid-column(8);
        }
        @include media(0, 950) {
            @include grid-column(12);
            &.image {
                @include grid-column(12);
            }
        }
        &.notes {
            position: absolute;
            right: rem-calc($gutter*0.75);
            top: 50%;
            transform: translateY(-50%);
            .caption {
                padding-left: 1.75rem;
                @include media(0, 1100) {
                    p {
                        font-size: 1.1em;
                    }
                }
                h4, h5 {
                    @extend %alt-heading;
                    margin-bottom: 1rem;
                }
                &:after {
                    content:"";
                    width: 50%;
                    display: block;
                    position: absolute;
                    height: 0;
                    right: 100%;
                    top: 0.75em;
                    margin-right: -2rem;
                    border-bottom: 1px solid $medium-gray;
                }
            }
            @include media(0, 950) {
                right: auto;
                top: auto;
                transform: none;
                position: static;
                margin-top: 1.5rem;
                margin-bottom: 0 !important;
                .caption {
                    padding-left: 0;
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }
}
.people {
    &.has-desc {
        .row.title {
            h3 {
                margin-bottom: 0.75rem;
            }
        }
    }
    .copy-container {
        p {
            margin-bottom: 1.75em;
        }
    }
    .items {
        margin-bottom: -2rem;
        &.hide-third {
            .columns:nth-child(3) {
                @include media(0, 1100) {
                    display: none;
                }
                @include small {
                    display: block;
                }
            }
        }
        &.four {
            .columns {
                @include grid-column(3);
                @include grid-column-end;
                // @include media(0, 1440) {
                //     @include grid-column(4);
                //     @include grid-column-end;
                //     &:last-child {
                //         display: none;
                //     }
                // }
                @include media(0, 1300) {
                    @include grid-column(6);
                    @include grid-column-end;
                    // &:last-child {
                    //     display: block;
                    // }
                }
                @include small {
                    @include grid-column(12);
                }
            }
        }
        .columns {
            @include grid-column(4);
            @include grid-column-end;
            margin-bottom: 2rem;
            @include media(0, 1100) {
                @include grid-column(6);
                @include grid-column-end;
            }
            @include small {
                @include grid-column(12);
            }
            .inner {
                background: $w;
                // padding: 2em;
                padding: 2.5rem 2.5rem 3rem 2.5rem;
                @include media(0, 1000) {
                    padding-left: 1.75rem;
                    padding-right: 1.75rem;
                }
                @include small {
                    padding: 1.75rem 1.5rem 2.5rem 1.5rem;
                }
                // padding-top: ((250/16)/2)+rem;
                // @include media(0, 1100) {
                    padding-top: ((215/16)/2)+rem;
                // }
                @include small {
                    padding-top: ((175/16)/2)+rem;
                }
            }
            .image {
                // width: (200/16)+em;
                position: relative;
                // margin: 0 auto (-((200/16)/2)+em) auto;
                // @include media(0, 1100) {
                    width: (180/16)+em;
                    margin: 0 auto (-((180/16)/2)+em) auto;
                // }
                @include media(0, 1100) {
                    width: (160/16)+em;
                    margin: 0 auto (-((160/16)/2)+em) auto;
                }
                @include small {
                    width: (140/16)+em;
                    margin: 0 auto (-((140/16)/2)+em) auto;
                }
                img {
                    border-radius: 100%;
                }
            }
        }
    }
}




