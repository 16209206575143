
// Hero
// ---------------------------------------

.black {
    color: $w;
    a {
        color: $w;
        @include st {
            opacity: 0.6;
        }
    }
}
#hero {
    position: relative;
    z-index: 2;
    background: $w;
    height: 87.5vh;
    max-height: 650px;
    min-height: 550px;
    @include media(0, 1440) {
        min-height: 600px;
    }
    .overflow-container {
        @include media(0, 1440) {
            overflow: hidden;
        }
    }
    @include media(0, $tablet-break) {
        max-height: none;
        min-height: 0;
        height: auto;
    }
    .row {
        height: 100%;
        position: relative;
        @include media(0, $tablet-break) {
            height: auto;
            position: relative;
            padding: 0;
        }
    }
    .columns {
        width: 37.5%;
        position: absolute;
        top: 50%;
        left: rem-calc($gutter*0.85);
        transform: translatey(-50%);
        padding-bottom: 3.5em;
        @include media(0, $tablet-break) {
            width: auto;
            max-width: 500px;
            position: static;
            top: auto;
            transform: none;
            padding-bottom: 0;
            padding-top: 2rem;
            margin-left: rem-calc($gutter*0.85);
        }
    }
    .overflow-container {
        position: absolute;
        left: 39.5%;
        height: 110%;
        top: -3%;
        width: 100 - 39.5%;
        @include media(0, $tablet-break) {
            left: auto;
            height: auto;
            top: 2.25rem;
            margin-top: 0rem;
            // margin-bottom: -5.5rem;
            width: 100%;
            position: relative;
        }
        @include small {
            top: 1.5rem;
            margin-top: 0rem;
        }
        @include media(0, 480) {
            top: 2rem;
            margin-top: 0rem;
        }
    }
    .banner {
        // display: none;
        // position: absolute;
        // left: 39.5%;
        // top: -3%;
        // width: 68.5%;
        text-align: center;
        width: 100%;
        font-size: 0.85em;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        @include media(0, $tablet-break) {
            position: static;
            top: auto;
            transform: none;
        }
        img {
            max-height: 110%;
            min-width: 950px;
            @include media(0, 1400) {
                min-width: 900px;
            }
        }
        .caption {
            background: $b;
            position: absolute;
            max-width: 350px;
            width: 35%;
            bottom: 11.5%;
            left: 0;
            text-align: left;
            padding: 1.2em 1.5em 1.5em 1.5em;
            p {
                margin: 0 0 0.25em 0;
            }
        }
        @include media(0, $tablet-break) {
            position: relative;
            width: auto;
            width: 120%;
            margin-left: -10%;
            max-height: none;
            min-width: 0;
            img {
                min-width: 0;
                max-height: none;
            }
            .caption {
                left: auto;
                right: 10%;
                bottom: 12.5%;
                max-width: 350px;
                width: 100%;
                margin-right: rem-calc($gutter*0.85);
            }
        }
        @include small {
            .caption {
                margin-right: rem-calc($gutter*0.2);
                width: 50%;
            }
        }
        @include media(0, 480) {
            // width: 105%;
            // margin-left: -2.5%;
            .caption {
                display: none;
            }
        }
    }
    @include small {
        .columns {
            margin-left: rem-calc($gutter*0.2);
            padding-top: 1rem;
        }
    }
    &.page {
        min-height: 0;
        max-height: none;
        height: auto;
        @include clear;
        .breadcrumb {
            // @extend p;
            position: relative;
            top: -1em;
            margin-bottom: 0.5em;
            p {
                font-size: 1.1em;
            }
            a {
                color: $b;
                text-decoration: underline;
                @include st {
                    color: $primary;
                }
            }
            span.sep {
                @extend %vam;
                color: $medium-gray;
                margin-left: 0.45rem;
                margin-right: 0.25rem;
                font-size: 1.1em;
            }
        }
        p {
            margin: 0;
        }
        h1 {
            margin-top: 0;
        }
        .row {
            height: auto;
            @include clear;
            padding-left: rem-calc($gutter*0.85);
            padding-right: rem-calc($gutter*0.85);
            @include small {
                padding-right: rem-calc($gutter*0.2);
                padding-left: rem-calc($gutter*0.2);
            }
        }
        .large-image {
            .banner {
                position: relative;
                display: block;
                text-align: center;
                width: 90%;
                margin: 0 auto;
                transform: none;
                // margin-left: -5%;
            }
            img {
                // margin-bottom: -25%;
                max-width: 95%;
                max-height: none;
                min-width: 0;
                margin-top: -1rem;
                margin-bottom: -20rem;
                max-width: 1200px;
                @include media(0, 1300) {
                    max-width: 100%;
                }
                @include media(0, 1000) {
                    margin-top: -1rem;
                }
                @include small {
                    margin-bottom: -8rem;
                    margin-top: -0.5rem;
                }
            }
        }
        .columns {
            position: static;
            transform: none;
            padding-top: 2rem;
            max-width: none;
            margin: 0;
            @include grid-column(8);
            @include media(0, 1000) {
                @include grid-column(7);
                padding-bottom: 3rem;
            }
            @include small {
                @include grid-column(12);
                padding-bottom: 2rem;
            }
            &.links {
                @include grid-column(3);
                @include media(0, 1300) {
                    @include grid-column(4);
                }
                @include media(0, 1000) {
                    @include grid-column(5);
                }
                padding-top: 4.5rem;
                @include small {
                    @include grid-column(12);
                    padding-top: 0;
                    // margin-top: 1.5rem;
                    margin-top: -0.5em;
                    padding-bottom: 2.5rem;
                }
                h5 {
                    margin-bottom: 0;
                    margin-top: 1rem;
                    font-size: 1.35rem;
                    &:first-child {
                        margin-top: 0;
                    }
                    @include small {
                        font-size: 1.2rem;
                    }
                }
                .cat-link {
                    display: block;
                    margin-bottom: 1rem;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
        &.main {
            .columns {
                @include grid-column(8);
                @include media(0, 1000) {
                    @include grid-column(8);
                }
                @include small {
                    @include grid-column(12);
                }
                &.links {
                    @include grid-column(3);
                    @include media(0, 1300) {
                        @include grid-column(4);
                    }
                    @include media(0, 1000) {
                        @include grid-column(4);
                    }
                    @include small {
                        @include grid-column(12);
                    }
                }
            }
        }
    }
}




