
// Form Styles
// ---------------------------------------

.submit-button {
	position: relative;
	z-index: 1;
	input[type=submit] {
		background: none;
	}
	&:after {
		content: "";
		display: inline-block;
		width: 99%;
		height: 5px;
		bottom: 1px;
		left: 0;
		position: absolute;
		z-index: -1;
		background: $primary;
		@include small {
			bottom: 2px;
		}
	}
	@include st {
		opacity: 1;
		&:after {
			bottom: 6px;
			@include small {
				bottom: 7px;
			}
		}
	}
}
.mc4wp-response {
	> div {
		text-align: left;
	    padding: 0.75rem 1rem;
	    background: red;
	    margin-top: 1em;
	}
    .mc4wp-alert, .mc4wp-error {
    	background: $alert;
    }
    .mc4wp-success {
    	background: $success;
    }
}
.form-container {
	text-align: right;
	max-width: 500px;
	h3, h4 {
		margin-top: 0;
	}
	p {
		margin: 0;
		font-size: 1em;
	}
	br {
		display: none !important;
	}
	.submit-button {
		display: inline-block;
	}
	label {
		text-align: left;
		font-size: 1.1em;
	}
	h3, h4, h5 {
		text-align: left;
	}
}
.columns.form .form-container {
	h3, h4, h5 {
		@extend %alt-heading;
	}
}
label {
	font-size: 1em;
	margin-bottom: 0.15em;
	span {
		color: $alert;
	}
}
input {
	&[type=text], &[type=phone], &[type=email], &[type=password] {
		@extend %form-style;
	}
}
textarea, textarea[rows] {
	@extend %form-style;
	height:150px;
	padding: 0.65em 1em;
	border: 1px solid $medium-gray;
	margin-top: 0.65rem;
	&:focus, &:active {
		border: 1px solid $dark-gray;
	}
	&.wpcf7-not-valid {
		border-color: red;
	}
}
select {
	@extend %form-style;
	@extend %custom-select;
}
input[type=checkbox] {
	@extend %checkbox;
}
.date-input {
	position: relative;
	input[type=text] {
		padding-right:3em;
		&[readonly] {
			background-color: $w;
			cursor: pointer;
		}
	}
	@include fa(calendar);
	&:before {
		position: absolute;
		color: $medium-gray;
		font-size: 1.5em;
		right: 0.75em;
		top: 50%;
		margin-top: -0.6em;
	}
}
$date-highlight-color: $light-gray;
$date-highlight-border-color: $medium-gray;
$date-highlight-focus-color: $dark-gray;
.picker {
	&__box {
		border-radius: $d-rad;
		font-size: 1.4rem;
	}
	&__year {
		color: inherit;
		font-style: normal;
		font-size: 1em;
	}
	&__month {
		margin:0;
	}
	&__weekday {
		font-weight: inherit;
	}
	&__day {
		font-weight: inherit;
		&--today {
			&:before {
				border-top-color: $secondary;
			}
		}
		&--infocus, &--outfocus {
			@include st {
				background-color: $date-highlight-color;
			}
		}
		&--selected {
			background-color: $date-highlight-color;
			color: $b;
			@include st {
				background-color: $date-highlight-color;
				color: $b;
			}
		}
		&--highlighted {
			background-color: $date-highlight-color;
			border-color: $date-highlight-border-color;
			@include st {
				background-color: $date-highlight-color;
			}
		}
	}
	&--focused {
		.picker__day--selected, .picker__day--highlighted {
			background-color: $date-highlight-focus-color;
			color: $b;
			@include st {
				background-color: $date-highlight-focus-color;
				color: $b;
			}
		}
	}
	&__nav {
		&--next {
			@include st {
				background-color: $date-highlight-color;
			}
		}
		&--prev {
			@include st {
				background-color: $date-highlight-color;
			}
		}
	}
	&__button {
		&--today, &--clear, &--close {
			color: $b;
			border-radius: 0;
			@include st {
				color: $b;
				background: $date-highlight-color;
				border-color: $date-highlight-color;
			}
		}
		&--today {
			&:before {
				border-top-color: $secondary;
			}
		}
		&--clear {
			&:before {
				border-top-color: $alert;
			}
		}
		&--close {
			&:before {
				border-top: $dark-gray;
			}
		}
	}
}

// Contact Form 7 Specific Styles
span.wpcf7-not-valid-tip {
	@extend %error-message;
	text-align: left;
	background: none;
	padding: 0;
	margin-top: -0.5rem;
	margin-bottom: 1rem;
	font-size: 1.2em;
	@include small {
		font-size: 1.1em;
	}
}
div.wpcf7-response-output {
	font-size: 1.1em;
	text-align: left;
	padding: 0 !important;
	font-weight: normal;
	border: 0 !important;
	margin: 1.5em 0 0 0 !important;
	@include small {
		font-size: 1.1em;
	}
	&.wpcf7-validation-errors {
		color: red;
	}
	&.wpcf7-mail-sent-ok {
		color: $success;
	}
}
.ajax-loader {
	display: none !important;
}

#moove_gdpr_cookie_modal, #moove_gdpr_save_popup_settings_button, #moove_gdpr_cookie_info_bar {
	// font-family: $body-font !important;
	button span:after, span:after {
		display: none;
	}
}

