
// Utilities
// ---------------------------------------

%section-pad {
	padding: 5em 0;
	@include media(0, 1000) {
		padding: 4em 0;
	}
	@include small {
		padding: 3em 0;
	}
}
%link-style {
	cursor: pointer;
	color: $primary;
}
%link-style_hover {
	cursor: pointer;
	color: $dark-gray;
	text-decoration: underline;
}
%button-style {
	transition: none;
	margin: 0;
	padding: 0;
	font-family: $heading-font;
	font-size: 1.35rem;
	color: $b;
	font-weight: bold;
	border-radius: 0;
	border: 0;
	font-weight: 700;
	@include small {
		font-size: 1.2rem;
	}
	.black & {
		color: $w;
	}
	span {
		position: relative;
		z-index: 1;
		&:after {
			content: "";
			display: inline-block;
			width: 99.9%;
			height: 5px;
			bottom: 0;
			left: 0;
			position: absolute;
			z-index: -1;
		}
	}
	@include st {
		opacity: 1;
		span:after {
			bottom: 5px;
		}
	}
}
%button-style-small {
	@extend %button-style;
	font-size: 1.25rem;
	span:after {
		bottom: 1px;
	}
	@include small {
		font-size: 1.2rem;
	}
	@include st {
		opacity: 1;
		span:after {
			bottom: 5px;
		}
	}
}
%button-style_hover {
	text-decoration: none;
}
%list-style {
	list-style: none;
	position: relative;
	@include pseudo;
	&:before {
		width: 0.4em;
		height: 0.45em;
		background: $b;
		position: absolute;
		left: -1.2em;
		top: 0.6em;
		@if $d-rad != 0 {
			border-radius: 100%;
		}
	}
}
%no-list {
	margin: 0;
	padding: 0;
	list-style: none;
	> li {
		margin: 0;
		padding: 0;
		list-style: none;
		&:before {
			display: none;
		}
	}
}

// Forms
%form-style {
	padding: 0.3em 0 0.65em 0;
	font-size: 1.1em;
	height: auto;
	border: 0;
	border-bottom: 1px solid $medium-gray;
	margin: 0 0 1.25em 0;
	background: none;
	box-shadow: none;
	appearance: none;
	@include ph-color($medium-gray);
	&.wpcf7-not-valid {
		border-color: red;
	}
	&:focus, &:active {
		box-shadow: none;
		border-color: $dark-gray;
		background: none;
		border: 0;
		border-bottom: 1px solid $dark-gray;
	}
}
%custom-select {
	background-color: $w;
	background-position: center right 0.25em;
	background-size:16px 9px;
	padding: 0.95em;
}
%checkbox {
	appearance: none;
	cursor: pointer;
	border-radius: $d-rad;
	width: 1.25em;
	height: 1.25em;
	margin: 0;
	border: 1px solid $medium-gray;
	background: $w;
	@include transition;
	@include fa(check);
	&:hover, &:focus, &:active {
		border-color: $dark-gray;
	}
	&:before {
		@include transition;
		color: $primary;
		font-size: 1.15em;
		opacity: 0;
	}
	&:checked {
		&:before {
			opacity: 1;
		}
	}
}
%filter-option {
	padding-left:2em;
	position: relative;
	> span {
		content:"";
		display: block;
		width: 1.25em;
		border-radius: $d-rad;
		height: 1.25em;
		border: 1px solid $medium-gray;
		position: absolute;
		top: 0.15em;
		left: 0;
		@include transition;
		@include fa(check);
		&:before {
			@include transition;
			color: $primary;
			position: relative;
			font-size: 1.15em;
			opacity: 0;
			top:-0.2em;
		}
	}
	&:hover, &:focus, &:active {
		> span {
			border-color: $dark-gray;
		}
	}
}
%filter-option-active {
	> span:before {
		opacity:1;
	}
}
%error-message {
	padding: 1em;
	background: $alert;
	color: $w;
	border: 0;
	font-weight: bold;
}
%success-message {
	padding: 1em;
	background: $success;
	color: $w;
	border: 0;
	font-weight: bold;
}




