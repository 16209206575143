
// Header
// ---------------------------------------

%svg {
    svg {
        width: 100%;
        height: 100%;
    }
}
%vam {
    display: inline-block;
    vertical-align: middle;
}
.bg {
    background: #f6f6f6;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: -1;
}
.static-bg {
    background: #f6f6f6 url('../img/bg.png') no-repeat center center;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: -1;
}
.overflow-hold {
    overflow: hidden;
    width: 100%;
}
body.restrict-height {
    height: 100vh;
    overflow: hidden;
}
header {
    font-family: $heading-font;
    font-size: 1.2em;
    text-transform: lowercase;
    padding: 2em 0;
    background: $w;
    position: relative;
    @include media(0, 1400) {
        padding: 1.75em 0;
    }
    @include small {
        padding: 1.35em 0;
    }
    @include media(0, 1000) {
        font-size: 1.1em;
    }
    .columns {
        position: relative;
    }
    .logo-holder {
        display: inline-block;
        a {
            text-decoration: none;
            color: $b;
        }
        #branding, span.title {
            @extend %vam;
        }
        span.title {
            margin-left: 0.35em;
        }
    }
    #branding {
        height: 2.625em;
        width: 2.625em;
        @extend %svg;
    }
    #menu_toggle {
        display: none;
        @include st {
            text-decoration: none;
            color: $medium-gray;
            span.text {
                text-decoration: underline;
            }
        }
        @include small {
            display: block;
        }
        span.close, span.nav {
            &:before {
                font-size: 1.1em;
                // @extend %vam;
                position: relative;
                top: 0.11em;
                margin-right: 0.1em;
            }
        }
        span.close {
            display: none;
            @include fa(times);
        }
        span.nav {
            @include fa(bars);
        }
        &.active {
            font-size: 1.15em;
            width: 100%;
            bottom: 0;
            padding-bottom: 2rem;
            text-align: center;
            position: absolute;
            span.nav {
                display: none;
            }
            span.close {
                display: block;
            }
        }
    }
    .navigation {
        position: absolute;
        top: 50%;
        padding-bottom: 0.1em;
        right: rem-calc($gutter*0.5);
        transform: translateY(-50%);
        @include small {
            padding-bottom: 0.2em;
            right: rem-calc($gutter*0.5);
        }
        a {
            color: $b;
            @include st {
                color: $medium-gray;
            }
        }
        ul {
            @extend %no-list;
            li {
                @extend %vam;
                margin-left: 1.2em;
                a {
                    color: $b;
                    @include st {
                        color: $medium-gray;
                    }
                }
                &.active, &.current-menu-item, &.current_page_item, &.current_page_parent, &.current-page-ancestor {
                    a {
                        color: $medium-gray;
                    }
                }
            }
        }
    }
    @include small {
        #menu {
            display: none;
        }
        .navigation.active {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            color: $w;
            z-index: 10;
            transform: none;
            background: $b;
            a {
                color: $w;
            }
            #menu {
                display: block;
                font-size: 1.15em;
                .inner {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 100%;
                    ul {
                        li {
                            display: block;
                            margin: 0;
                            text-align: center;
                            margin-bottom: 0.5rem;
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}




